<template>
	<div>
		<section class="header-bg se43" style="">
			<div class="container-1200" style="padding-top: 20px;">
				<div class="login_logo">
					<img :src="require('@/assets/images/logo.png')" @click="goToIndex"/>
				</div>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong>BROWSE LOGIN</strong></p>
			</div>
			<div style="margin: 80px auto;min-height: 510px;">
				<div :class="!registrationShow?'toLogin layui-hide':'toLogin'">
					<el-form class="layui-form login-form" action="" lay-filter="example1" :rules="rules"
						:model="loginForm" ref="form">
						<h2 style="margin-bottom: 30px;font-size: 30px;"><strong>Log in</strong></h2>
						<!-- <div class="layui-form-item"> -->

						<el-form-item>
							<div class="layui-input-block">
								<input type="text" v-model="loginForm.eMail" name="username" lay-verify="required"
									lay-reqtext="Email required!" autocomplete="off" placeholder="Email"
									class="layui-input">
							</div>
						</el-form-item>
						<!-- </div> -->
						<!-- <div class="layui-form-item"> -->
						<el-form-item>
							<div class="layui-input-block">
								<input type="password" v-model="loginForm.userPassword" name="password"
									lay-verify="required" lay-reqtext="password required!" autocomplete="off"
									placeholder="password" class="layui-input">
							</div>
						</el-form-item>
						<!-- </div> -->
						<div>
							<input type="checkbox" name="rememberPassword" lay-skin="primary" title="Remember password">
							<p class="lost-your-password right float-hand-color noaccount" @click="registrationBtn">
								Registration Now?</p>
							<!-- <p class="lost-your-password right float-hand-color  lostyour">Lost your password?</p> -->
						</div>
						<div>
							<button type="button" class="layui-btn layui-btn-fluid" style="margin-top: 50px;"
								@click="loginBtn" lay-submit="" lay-filter="demo1">Log In</button>
						</div>
					</el-form>
				</div>
				<div :class="registrationShow?'toSignUp layui-hide':'toSignUp'">
					<form class="login-form" action="" lay-filter="example2" :rules="rules" ref="form">
						<h2 style="margin-bottom: 30px;font-size: 30px;" ><strong>Sign UP</strong></h2>
						<h3 style="font-size: 20px;">Please select your identity</h3>
						<div class="layui-form-item">
							<div class="layui-input-block" style="display: flex">
								<el-radio-group v-model="sel" style="margin-top: 10px;">
									<el-radio :label="item" v-for="(item,index) of radio" :key="index"
										@change="radioBtn(index)">{{item}}</el-radio>
								</el-radio-group>
							</div>
						</div>
						<div class="layui-form-item">
							<div class="layui-input-block">
								<input type="text" name="userName" v-model="form.userName" autocomplete="off"
									placeholder="User Name" lay-verify="required" lay-reqtext="User Name required!"
									class="layui-input">
							</div>
						</div>
						<div class="layui-form-item">
							<div class="layui-input-block">
								<input type="text" name="eMail" v-model="form.eMail" autocomplete="off"
									placeholder="Email" lay-verify="required" lay-reqtext="Email required!"
									class="layui-input">
							</div>
						</div>
						<div class="layui-form-item">
							<div class="layui-input-block">
								<input type="text" name="PhoneNum" v-model="form.phone" autocomplete="off"
									placeholder="Phone" lay-verify="required" lay-reqtext="Phone required!"
									class="layui-input">
							</div>
						</div>
						<div :class="radioIndex==0?'layui-form-item author':'layui-form-item author layui-hide'">
							<div class="layui-input-block">
								<input type="text" name="researchDirection" v-model="form.researchDirection"
									autocomplete="off" placeholder="Research direction"
									lay-reqtext="Research direction required!" class="layui-input">
							</div>
						</div>
						<div :class="radioIndex==0?'editor layui-hide':'editor'">
							<div class="layui-form-item">
								<div class="layui-input-block">
									<input type="text" name="Publisher" v-model="form.publisher" autocomplete="off"
										placeholder="Publisher" lay-reqtext="Publisher required!" class="layui-input">
								</div>
							</div>
							<div class="layui-form-item">
								<div class="layui-input-block">
									<input type="text" name="journal" v-model="form.journal" autocomplete="off"
										placeholder="Journal" lay-reqtext="Journal required!" class="layui-input">
								</div>
							</div>
							<div class="layui-form-item">
								<div class="layui-input-block">
									<input type="text" name="userRole" v-model="form.role" autocomplete="off"
										placeholder="Role" lay-reqtext="Role required!" class="layui-input">
								</div>
							</div>
						</div>
						<div class="layui-form-item">
							<div class="layui-input-block">
								<input type="password" name="userPassword" v-model="form.userPassword" lay-verify="required"
									lay-reqtext="password required!" autocomplete="off" placeholder="password"
									class="layui-input">
							</div>
						</div>

						<div class="layui-row">
							<div class="layui-col-md8">
								<div class="layui-form-item">
									<div class="layui-input-block">
										<input type="text" name="verificationCode" v-model="form.verificationCode"
											autocomplete="off" lay-verify="required"
											lay-reqtext="Verification Code required!" placeholder="Verification Code"
											class="layui-input">
									</div>
								</div>
							</div>
							<div class="layui-col-md4">
								<button type="button" class="layui-btn countDown" style="height: 50px;min-width: 183px;"
									@click="getCode">Send Verification Code
								</button>
							</div>
						</div>
						<div>
							<p class="lost-your-password right float-hand-color" @click="registrationShow = true">Log
								In?
							</p>
						</div>

						<div>
							<button type="reset"
								class="layui-btn layui-btn-primary layui-hide toSignUpReset">重置</button>
							<button type="button" class="layui-btn layui-btn-fluid" lay-submit="" lay-filter="demo2"
								style="margin-top: 50px;height: 50px;" @click="signBtn">Sign Up
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	</div>

</template>
<script>
	import $http from "@/request/http";
    import Cookies from "js-cookie";


	export default {
		//store.getters.xxx 

		//import { useStore } from 'vuex'
		//setup () {
		//const store = useStore()
		//store.getters.xxx
		//}


		created() {

			this.$emit("header", false);
			this.$emit("Footer", false);
			this.type = this.$route.path;
		},
		data() {
			var userName2 = (rule, value, callback) => {
				const mailReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;

				if (!value) {
					return callback(new Error('Please enter your phone number.'))
				}
				setTimeout(() => {
					if (mailReg.test(value)) {
						callback()
					} else {
						callback(new Error('Please enter the correct phone number.'))
					}
				}, 100)
			};
			return {
				//是否登录状态，这里并不重要
				isLogin: this.$store.getters.getIsLogin,
				radioIndex: 0,
				sel: "author",
				radio: ["author", "editor"],
				registrationShow: true,
				type: "/login",
				loginForm: {
					eMail: "",
					userPassword: ""
				},
				//注册form
				form: {
					userName: "",
					eMail: "",
					phone: "",
					verificationCode: "",
					researchDirection: "",
					publisher: "",
					journal: "",
					role: "",
					userPassword: "",
					againPassWord: "",
				},
				rules: {
					email: [{
						required: true,
						trigger: "blur",
						validator: "Please enter email"
					}],
					userName: [{
						trigger: "blur",
						validator: userName2,
					}, ],
					userPassword: [{
						required: true,
						trigger: "blur",
						message: "Please enter password"
					}],
					code: [{
						required: true,
						trigger: "blur",
						message: "Please enter the verification code"
					}],
				},
			};
		},
		methods: {
			goToIndex(){
				this.$router.push("/index");	
			},
			radioBtn(index) {
				this.form = {}
				this.radioIndex = index
			},
			registrationBtn() {
				this.registrationShow = false
				this.form = {}
			},
			//author注册接口
			signBtn() {
				
				this.form.identity = this.sel
				const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
				const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
				
				if (this.form.userName == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter your username first!",
					});
					return false
				}
				if (this.form.eMail == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter your email first!",
					});
					return false
				}

				if (!mailReg.test(this.form.eMail)) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "The mailbox format is incorrect.",
					});
					return false
				}
				if (this.form.phone == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter the phone number first.",
					});
					return false
				}
				if (!phoneReg.test(this.form.phone)) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter the phone number first.",
					});
					return false
				}
				if (this.form.userPassword.length < 4) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Password must be longer than three digits.",
					});
					return false;
				}
				$http.loginTrue("contrimetricSignup", this.form, true).then(res => {
					if(res.code == 200){
						this.$message.success({
							showClose: true,
							duration: 1500,
							message: "Registered successfully !",
						});
						this.registrationShow = true
					}
				}).catch(err => {
					console.log(err);
				})
			},
			//登录接口
			loginBtn() {
				const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
				if (this.loginForm.eMail == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter your email first.",
					});
					return false
				}
				if (!mailReg.test(this.loginForm.eMail)) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Email format is incorrect.",
					});
					return false
				}
				if (this.loginForm.userPassword.length < 4) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Password must be longer than four digits.",
					});
					return false;
				}
				$http.loginTrue('contrimetricLogin', this.loginForm, true).then(res => {
				
				// 	const currentTime = Date.now()
				// 	const expireTime = currentTime + (res.data.tokenInfo.tokenTimeout-60) * 1000
				// 	localStorage.setItem('expireTime', JSON.stringify(expireTime))
					

					this.$store.commit("setUserInfo", res.data.username)
					this.$store.commit("setTokenInfo", res.data.tokenInfo)
					this.$store.commit("setExpireTime",res.data.tokenInfo.tokenTimeout)
					this.$store.commit("setIsLogin", true)
					this.$emit("header", true);
					this.$emit("Footer", true);
					this.$router.push({
						path: "/index"
					});
				}).catch(err => {
					console.log(err);
				})
			},
			//获取验证码
			getCode() {
				const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
				const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
				if (this.form.userName == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter your username first.",
					});
					return false
				}
				if (this.form.eMail == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter your email first.",
					});
					return false
				}
				if (!mailReg.test(this.form.eMail)) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Email format is incorrect.",
					});
					return false
				}
				if (this.form.phone == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Please enter the phone number first.",
					});
					return false
				}
				if (!phoneReg.test(this.form.phone)) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "The phone number format is incorrect.",
					});
					return false
				}
				if (this.form.userPassword.length < 4) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "Password must be longer than three digits.",
					});
					return false;
				}
				$http.loginCode('send/contrimetricsVerificationCode', this.form, true).then(res => {

				}).catch(err => {
					console.log(err);
				})
			},
			//导航栏跳转
			handleSelect(index) {
				this.$router.push(index);
			},
		}

	};
</script>
<style lang="scss" scoped>
	.login_logo{
		margin-top: 40px;	
		
		img{
			&:hover{
				cursor: pointer;
			}
		}
	}

	.se43{
		height: 251px;
	}

	@media (max-width: 768px) {
    .se43{
		height: 175px;

		.login_logo{
			img{
				width: 45%;
    			margin-left: 15px;
			}
		}
	}
}
	
	.index-meeting .el-image__inner {
		vertical-align: bottom !important;
	}

	//导航栏的修改


	// .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item[data-v-a83bd3b0]:not(.is-disabled):hover {

	// }


	::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
	.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
		outline: 0;
		/* color: #303133; */
	}

	::v-deep .el-menu {
		background-color: transparent;
	}

	::v-deep .child ::v-deep .el-menu {
		background-color: transparent;
	}

	::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
	.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
		color: #fff;
		background-color: transparent;
	}

	::v-deep .el-menu.el-menu--horizontal {
		border-bottom: none;

	}

	::v-deep .el-menu--horizontal>.el-menu-item {
		color: #fff;
		font-size: 16px;
	}

	::v-deep .el-menu--horizontal>.el-menu-item:focus {
		color: #fff;
	}

	::v-deep .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom-color: #1da6b8;
		color: #fff;
	}

	::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
		color: #fff;

	}

	::v-deep .el-submenu__title:focus,
	.el-submenu__title:hover {
		// background-color: transparent;
	}

	::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
		background-color: transparent;
		color: #fff;
	}

	::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:active {
		// color:#fff;
	}


	//导航栏的修改


	::v-deep .el-radio__input.is-checked .el-radio__inner {
		border-color: #1da6b8;
		background: #1da6b8;
		
	}
	
	::v-deep .el-radio__inner:hover{
		border-color: #1da6b8;
	}
	::v-deep .el-radio__input.is-checked+.el-radio__label{
		color:#1da6b8;
	}
</style>

<style>
	/* .el-menu--horizontal .el-menu  */
	/deep/ .el-menu--horizontal>ul>li {

		color: #000;
	}

	/* 	/deep/ .child  .el-submenu__title {
		
			color: #fff;
		} */

	/deep/ .child .el-menu--horizontal {

		color: #fff;
	}
</style>